/**Este es el detalle de venta aprovada */
import {
  Card,
  CardContent,
  Grid,
  Box,
  Fab,
  Tooltip,
  Divider,
  Chip,
  Button,
} from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import WarehouseTickets from "../../components/Cards/Warehouse/WarehouseTickets";
import { makeStyles } from "@mui/styles";
import MethodGet from "../../config/Service";
import BuildingComponent from "../../components/loading/BuildingComponent";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddPayment from "./UpdateSale/AddPayment";
import {
  AddressInfo,
  ClientInfo,
  DateTime,
  SaleOrder,
  TableSaleOrderProducts,
} from "../../components/Detail";
import DetailsCanvas from "./DetailsCanvas";
import Swal from "sweetalert2";
import AttachFileEvidence from "../../components/Detail/AttachFileEvidence";
import ShowFileEvidence from "../../components/Detail/ShowFileEvidence";

const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },

  fab: {
    position: "relative",
    marginTop: 2,
  },
}));

export default function ShowSaleAll(props) {
  const id = props.match.params.id;
  const classes = useStyles();

  const [cargando, spinnerCargando] = useState(false);

  //let OrderStorageID = JSON.parse(sessionStorage.getItem("salePending"));
  const [Order, saveOrder] = useState("");
  const [metadata, setMetadata] = useState(null);
  const [payments, savePayments] = useState([]);
  const [paymentCard, savePaymentCard] = useState([]);
  const [shipping, saveShipping] = useState([]);
  const [evidence, GetEvidence] = useState([]);
  const { products_list } = Order;
  //const { bank_account_id } = Order;
  const { currency } = Order;

  const [total_payments, saveTotalPayments] = useState();

  /**Termina paginacion */
  const pagos = payments.map((p, index) => p.amount);
  const initialValueTotal = 0;
  const sumaTotalpagos = pagos.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValueTotal
  );
  useEffect(() => {
    let url = `/orders/${id}`;
    MethodGet(url)
      .then((res) => {
        saveOrder(res.data.order);
        if (res.data.stripe_payment) {
          savePaymentCard(res.data.stripe_payment.payment);
        }
        if (res.data.order.evidences) {
          GetEvidence(res.data.order.evidences);
        }
        savePayments(res.data.payments);
        saveShipping(res.data.shipping);
        saveTotalPayments(res.data.totalPayments);
        setMetadata(res.data.metadata);
        spinnerCargando(true);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });

        spinnerCargando(true);
      });
  }, [id]);
  /**modal Adjuntar evidencia */
  const [openEvidence, setOpenEvidence] = useState(false);

  const handleClickOpenEvidence = () => {
    setOpenEvidence(true);
  };

  const handleCloseEvidence = () => {
    setOpenEvidence(false);
  };
  /**modal Ver evidencia */
  const [openShowEvidence, setOpenShowEvidence] = useState(false);

  const handleClickOpenShowEvidence = () => {
    setOpenShowEvidence(true);
  };

  const handleCloseShowEvidence = () => {
    setOpenShowEvidence(false);
  };
  /**Modal pagos */
  const [openPayment, setOpenPayment] = useState(false);
  const handleClickOpenPayment = () => {
    setOpenPayment(true);
  };
  const handleClosePayment = () => {
    setOpenPayment(false);
  };

  //operaciones
  const initialValue = 0;
  const sumaTotal = payments.map((p, index) => p.amount);
  const sumaTotales = sumaTotal.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue
  );
  if (!cargando) return <BuildingComponent />;
  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "black",
            color: "white",
          }}
        >
          <div className={classes.titleCard}>Detalle de orden </div>
          <div className={classes.fab}>
            <Tooltip title='Regresar'>
              <Fab
                size='small'
                sx={{
                  color: "white",
                  backgroundColor: "black",
                  borderRadius: "24px",
                  marginRight: 1,
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                  },
                }}
                onClick={() => props.history.goBack()}
              >
                <ArrowCircleLeftOutlinedIcon />
              </Fab>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
      {shipping?.statusShipping !== 1 && (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display='flex'
            justifyContent='flex-end'
          >
            {evidence.length < 3 ? (
              <Button
                variant='contianed'
                startIcon={<AttachFileIcon />}
                sx={{
                  backgroundColor: "#000",
                  color: "white",
                  marginRight: 2,
                  "&:hover": { backgroundColor: "#000", color: "white" },
                }}
                onClick={handleClickOpenEvidence}
              >
                Adjuntar Evidencia
              </Button>
            ) : (
              evidence.length >= 3 && (
                <Button
                  variant='contianed'
                  startIcon={<AttachFileIcon />}
                  sx={{
                    backgroundColor: "#000",
                    color: "white",
                    marginRight: 2,
                    "&:hover": { backgroundColor: "#000", color: "white" },
                  }}
                  onClick={handleClickOpenShowEvidence}
                >
                  Ver Evidencia
                </Button>
              )
            )}
          </Grid>
        </Grid>
      )}
      <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
              sx={{
                boxShadow: 3,
                padding: 2,
              }}
            >
              <DateTime Order={Order} />
              <ClientInfo
                fullname={Order.client_id.fullname}
                email={Order.client_id.email}
                phone={Order.client_id.phone.phone_number}
              />
              <AddressInfo direction={Order.shippment_direction} />
              <SaleOrder
                folio={Order.folio}
                order_id={Order._id}
                orderStatus={Order.orderStatus}
                total={Order.total.toFixed(2)}
                subtotal={Order.subtotal.toFixed(2)}
                shippment={Order.shippment.toFixed(2)}
                shipping={shipping}
                currency={Order.currency}
                subtotalCurrency={Order.subtotalCurrency}
                totalCurrency={Order.totalCurrency}
                shippmentInCurrency={Order.shippmentInCurrency}
                discount_business_rule_id={Order.business_rule_id}
                discount_coupon_id={Order.coupon_id}
                type={Order ? Order.type : null}
                origin={Order ? Order.origin : null}
                metadata={metadata ? metadata : null}
                //comments={Order.comment}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            backgroundColor: "black",
            color: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className={classes.titleCard}>Detalle de la orden </div>
        </Grid>
      </Box>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Card>
          <CardContent>
            <TableSaleOrderProducts products_list={products_list} />
          </CardContent>
        </Card>
      </Grid>
      <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "black",
              color: "white",
            }}
          >
            <div className={classes.titleCard}>Pagos de la orden </div>
          </Grid>
          {total_payments < Order.total && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display='flex'
              justifyContent='end'
            >
              <Button variant='contained' onClick={handleClickOpenPayment}>
                Agregar Pago
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box
        sx={{ width: "100%", display: "flex", paddingX: 3, marginBottom: 4 }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider>
            <Chip
              sx={{ fontWeight: "bold", fontSize: 18 }}
              label={
                `Total Pagado: $ ` +
                Number(total_payments) +
                " / Total a pagar: $ " +
                Number(Order?.total.toFixed(2)) +
                " / Resta por pagar: $ " +
                Number(Order?.total.toFixed(2) - sumaTotalpagos)
              }
              icon={<CreditScoreIcon />}
            />
          </Divider>
        </Grid>
      </Box>
      <Box
        sx={{ width: "100%", display: "flex", paddingX: 3, marginBottom: 4 }}
      >
        <Grid container spacing={2}>
          {payments.map((payment, index) => (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={index}>
              <WarehouseTickets payments={payment} />
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* <Box
        sx={{
          width: "100%",
          display: "flex",
          paddingX: 3,
          marginBottom: 4,
          paddingBottom: 10,
        }}>
        {products_list.map((product, index) => (
          <>
            {product.product.product_type === "2" && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                <DetailsCanvas
                  product={product}
                  multimedia={product.canvas_multimedia}
                  order_id={Order._id}
                />
              </Grid>
            )}
          </>
        ))}
      </Box> */}
      <AttachFileEvidence
        id={Order._id}
        open={openEvidence}
        handleClose={handleCloseEvidence}
        GetEvidence={GetEvidence}
        evidence={evidence}
      />
      {evidence.length >= 3 && (
        <ShowFileEvidence
          id={Order?._id}
          open={openShowEvidence}
          handleClose={handleCloseShowEvidence}
          images={evidence}
        />
      )}
      {Order && (
        <AddPayment
          currency={currency}
          topay={Order?.total - sumaTotalpagos}
          open={openPayment}
          id={id}
          handleClose={handleClosePayment}
        />
      )}
    </Layout>
  );
}
